/* eslint-disable @typescript-eslint/no-explicit-any */
import { OverlayContainer } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  inject,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { toggleAnimation2 } from '@common/common/animations';
import { AvatarBarComponent } from '@common/components/avatar-bar/avatar-bar.component';
import { HeaderLogoComponent } from '@common/components/header-logo/header-logo.component';
import { PageRightDrawerContentDirective } from '@common/directives/page-right-drawer-content.directive';
import { StandaloneComponentModule } from '@common/modules';
import { RightDrawerService } from '@common/services/right-drawer.service';
import { filter, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'assets-simple-wrapper',
  templateUrl: './simple-wrapper.component.html',
  imports: [StandaloneComponentModule, HeaderLogoComponent, AvatarBarComponent],
  animations: [toggleAnimation2],
})
export default class SimpleWrapperComponent implements AfterViewInit {
  hasBackdrop: boolean;
  @ViewChild('rightDrawer') rightDrawer: MatDrawer;
  @ViewChild(PageRightDrawerContentDirective, { static: true })
  rightDrawerContent: PageRightDrawerContentDirective | undefined;
  projectedPage: any;
  hasRightDrawer: boolean = false;
  rightDrawerComponentRef: ComponentRef<any> | null = null;
  rightDrawerComponent: any | null = null;
  navigation$: Observable<boolean>;
  rightDrawerService = inject(RightDrawerService);
  overlayContainerEl = inject(OverlayContainer).getContainerElement();
  protected readonly destroy$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {
    this.navigation$ = this.router.events.pipe(
      map(event => {
        switch (event.constructor) {
          case NavigationStart:
            this.loadRightDrawerComponent(null);
            return true;
          case NavigationEnd:
          case NavigationCancel:
          case NavigationError:
            return false;
          default:
            return null;
        }
      }),
      filter((value): value is boolean => value !== null),
    );

    this.detetectOverlay();
  }

  private detetectOverlay() {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        this.isOverlayOpened = Boolean(mutation.target.childNodes.length);
      });
    });

    observer.observe(this.overlayContainerEl, {
      childList: true,
      subtree: false,
    });

    this.destroy$.subscribe(() => observer.disconnect());
  }

  isOverlayOpened = false;

  ngAfterViewInit(): void {
    this.rightDrawerService.drawerComponent$
      .pipe(takeUntil(this.destroy$))
      .subscribe(cmp => {
        this.rightDrawer.close();
        if (cmp) {
          this.rightDrawerComponent = cmp;
          this.hasRightDrawer = true;
        } else if (!cmp) {
          this.rightDrawerComponent = null;
          this.hasRightDrawer = false;
        }
        this.cd.detectChanges();
      });
  }

  loadRightDrawerComponent(projectedPageComponent: any) {
    const viewContainerRef = this.rightDrawerContent?.viewContainerRef;

    if (viewContainerRef) {
      viewContainerRef.clear();
      this.rightDrawerComponentRef = null;
      if (projectedPageComponent) {
        this.rightDrawerComponentRef = viewContainerRef.createComponent<any>(
          projectedPageComponent,
        );
      }
    }
  }

  onRightDrawerOpen() {
    if (
      this.rightDrawerComponent &&
      (!this.rightDrawerComponentRef ||
        !(
          this.rightDrawerComponentRef.instance instanceof
          this.rightDrawerComponent
        ))
    ) {
      this.loadRightDrawerComponent(this.rightDrawerComponent);
    }
  }

  componentAdded($event: any) {
    this.projectedPage = $event;
    this.loadRightDrawerComponent($event.rightDrawer?.component);
    this.hasRightDrawer = !!this.projectedPage?.rightDrawer;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
