<mat-progress-bar
  *ngIf="navigation$ | async"
  class="nav-progress-bar"
  mode="indeterminate"
></mat-progress-bar>
<div class="light-theme h-100 w-100 assets-wrapper">
  <nav class="assets-navbar justify-content-between">
    <app-header-logo></app-header-logo>
    <div class="d-flex">
      <button
        *ngIf="hasRightDrawer"
        mat-icon-button
        color="primary"
        (click)="rightDrawer.toggle()"
      >
        <mat-icon>{{
          projectedPage?.rightDrawer?.icon ?? 'filter_list'
        }}</mat-icon>
      </button>
      <app-avatar-bar [disabled]="true"></app-avatar-bar>
    </div>
  </nav>
  <mat-drawer-container autosize [hasBackdrop]="hasBackdrop">
    <div class="page">
      <router-outlet (activate)="componentAdded($event)"></router-outlet>
      <ngx-spinner name="root"> </ngx-spinner>
    </div>
    <mat-drawer
      #rightDrawer
      class="right-drawer"
      mode="over"
      opened="false"
      position="end"
      (openedStart)="onRightDrawerOpen()"
    >
      <ng-template assetsPageRightDrawerContent></ng-template>
    </mat-drawer>
  </mat-drawer-container>
</div>
