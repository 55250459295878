<mat-drawer-container autosize class="assets-table-drawer-container" [hasBackdrop]="true">
  <mat-drawer-content class="d-flex flex-column unscrollable-tab-content">
    <section class="d-flex flex-column unscrollable-tab-content" [class.assets-table-full-width]="hasFullWidth">
      <div #tableWrapper class="table-wrapper" (scroll)="onScroll()">
        <assets-table-controls *ngIf="dataService" />
        <mat-table
          class="table-white"
          [trackBy]="trackById"
          [dataSource]="data ?? dataService?.data"
        >
          <!-- Select Definition -->
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                (change)="$event ? masterToggle() : null"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox
                [checked]="selection.isSelected(row)"
                (click)="$event.stopPropagation()"
                (change)="rowSelect(row)"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <!-- <ng-content></ng-content> -->

          <!-- Header and Row Declarations -->
          <mat-header-row
            *matHeaderRowDef="getDisplayedColumns(); sticky: true"
          ></mat-header-row>
          <ng-container *ngIf="!rowDefs.length">
            <ng-container *ngIf="linkedRows">
              <mat-row
                *matRowDef="let row; columns: getDisplayedColumns()"
                [routerLink]="
                  [row?.id]
                    | prefixedLink: contextDirective?.context?.linkPrefix
                "
              ></mat-row>
            </ng-container>
            <ng-container *ngIf="!linkedRows">
              <mat-row
                *matRowDef="let row; columns: getDisplayedColumns()"
              ></mat-row>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="hasFooter">
            <mat-footer-row
              *matFooterRowDef="getDisplayedColumns(); sticky: true"
            ></mat-footer-row>
          </ng-container>
        </mat-table>
      </div>
      <ng-container *ngIf="hasPaginator">
        <div class="paginator table__pagination">
          <mat-paginator
            showFirstLastButtons
            [length]="tablePaginator.totalCount"
            [pageSize]="tablePaginator.pageSize"
            [pageSizeOptions]="tablePaginator.pageSizeOptions"
          ></mat-paginator>
        </div>
      </ng-container>
    </section>
  </mat-drawer-content>
  <mat-drawer
    #drawer
    mode="over"
    class="right-drawer"
    opened="false"
    position="end"
  >
    <ng-template assetsPageRightDrawerContent></ng-template>
  </mat-drawer>
</mat-drawer-container>
<ng-container *ngIf="dataService?.hasSpinner">
  <assets-spinner
    *ngIf="
      dataService?.spinnerName === 'page-host' ||
      (dataService?.loading$ | async) as loading
    "
    [name]="dataService?.spinnerName ?? 'app-table'"
  />
</ng-container>
