import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RightDrawerService {
  private _drawerComponent$ = new BehaviorSubject<Type<unknown> | null>(null);
  public drawerComponent$ = this._drawerComponent$.asObservable();

  setDrawerComponent(cmp: Type<unknown> | null) {
    this._drawerComponent$.next(cmp);
  }
}
