import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { FormInputComponent } from '@common/components/form/form-input/form-input.component';
import { I18nService } from '../../i18n';
import { MAX_DATE_STRING } from '@common/const/form.const';

@Component({
  selector: 'assets-form-datepicker',
  templateUrl: './form-datepicker.component.html',
  styleUrls: ['./form-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormDatepickerComponent
  extends FormInputComponent
  implements OnInit, OnChanges
{
  @Output() filterDateValueChanged = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Input() range = false;
  @Input() override placeholder = 'DATE_FORMAT';
  @Input() override type: 'date' | 'month' | 'year' = 'date';
  @ViewChild(MatInput, { read: ElementRef, static: true }) inputRef: ElementRef;
  minDateString = '';
  maxDateString = MAX_DATE_STRING;

  @Input() set minDate(value: Date | string | null) {
    if (value) {
      const date = typeof value === 'string' ? new Date(value) : value;
      this.minDateString = Intl.DateTimeFormat('fr-CA').format(date);
    } else {
      this.minDateString = '';
    }
  }

  @Input() set maxDate(value: Date | string | null) {
    if (typeof value === 'string') {
      this.maxDateString = value;
    } else if (value instanceof Date) {
      this.maxDateString = Intl.DateTimeFormat('fr-CA').format(value);
    } else {
      this.maxDateString = MAX_DATE_STRING;
    }
  }

  constructor(
    public dateAdapter: DateAdapter<Date>,
    public i18Service: I18nService,
  ) {
    super();
    this.dateAdapter.setLocale(i18Service.currentLanguage);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes.controlValue) {
      this.formatControlValue();
    }
    super.ngOnChanges(changes);
  }

  private formatControlValue() {
    const endIndex = this.type === 'month' ? 7 : 10;
    if (this.control.value) {
      this.control.patchValue(this.control.value?.substring(0, endIndex), {
        emitEvent: false,
      });
    }
    this.minDateString = this.minDateString?.substring(0, endIndex);
    this.maxDateString = this.maxDateString?.substring(0, endIndex);
  }

  onBlur(): ValidationErrors | null {
    const inputElement: HTMLInputElement = this.inputRef.nativeElement;

    inputElement.reportValidity();
    this.control.updateValueAndValidity();

    if (!inputElement.checkValidity()) {
      this.control.setErrors({ invalidDate: true });
    } else {
      if (this.control.value === '' && this.control.valid) {
        this.control.setValue(null);
      }
    }

    return null;
  }

  onDateChange(event: Event) {
    if (this.control.value === '' && this.control.valid) {
      this.control.setValue(null);
    }
    this.onChange.emit(event);
  }
}
